import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userProfile: {},
    name: '',
    test: {},
    shoppingCart: [],
    orderHistory: [],
    orderLocation: '',
    changedFacility: 0,
  },
  mutations: {
    setUserProfile(state, val){
      state.userProfile = val
    },
    setOrderHistroy(state, val){
      state.orderHistory = val
    },
    addItems (state, list) {
      if(!state.shoppingCart){
        state.shoppingCart = []
      }
      state.shoppingCart.push(list)
    },
    removeItems (state, n) {
      let i = 0
      while (i < n) {
        state.shoppingCart.pop()
        i += 1
      }
    }
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    //////////////////// change facility ///////////////////////////
    async setFacility({ dispatch}, target){
        const facilities = {
          '81' : { root: '16393801', facility: '16495499', inventory: '16394019', customAtt: 164679},
          '810' : { root: '16635839', facility: '16635879', inventory: '16635890', customAtt: 164679},
          'STZ' : { root: '16624160', facility: '16624416', inventory: '16624424', customAtt: 164679},
          'w3' : { root: '15127806', facility: '15505335', inventory: '16936612', customAtt: 119655},
          'SL' : { root: '16157322', facility: '16128633', inventory: '16157324', customAtt: 154688},
          'ADS' : { root: '16495478', facility: '16495491', inventory: '16495724', customAtt: 164679}
        }
        console.log("setFacility fired: ")
        console.log(target)
        console.log(facilities)
        if(!this.state.changedFacility) {
          this.state.orderLocation = target
          console.log("ordering to: " + this.state.orderLocation)
          this.state.changedFacility = 1
        } else {
          this.state.orderLocation = target
          this.state.changedFacility = 1
        }
    },
    ///////////////////////////////////////////////////////////////
    async getUserInfo({ dispatch }){
      const user = await fb.auth.currentUser
      const result = fb.usersCollection.doc(user.uid)
      let output = [];
      await result.get().then(doc => {
          if (doc.exists){
              output.push(doc.data())
              if(!this.state.changedFacility){
                  this.state.name = output[0].name.substring(0, output[0].name.indexOf(' '))
                  this.state.root = output[0].root
                  this.state.facility = output[0].facility
                  this.state.inventory = output[0].inventory
                  this.state.customAtt = output[0].customAtt
                  this.state.orderLocation = output[0].title
              }
          }
      }).catch( err => {
          console.log("Error in getting log in")
      })
      // console.log(output)
      return output
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()

      // set user profile in state
      commit('setUserProfile', userProfile.data())

      // change route to dashboard
      router.push('/')
    },
    async signup({ dispatch }, form) {
      // sign user up
      console.log("in signup")
      console.log(form)
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user profile object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        name: form.name,
        title: form.title,
        additional: []
      })
      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async logOrder({dispatch}, form){
      console.log("at entry point")
      console.log(form.order)
      let addDays = function(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

      let findOrdersBetween = async function(title){
        const orders = [];
        let now = new Date().getDay()
        let start = new Date()
        let end = new Date()
        switch (now) {
          // Sunday -> Tuesday
          case 0:
            start.setDate(start.getDate() - 3)
            start.setHours(15,0,0)
            end.setHours(14,59,59)
            end = addDays(end, 1)
            break 
          // Monday -> Tuesday / Thursday
          case 1:
            end.setHours(14,59,59)
            if (end > new Date()){
                start.setHours(15,0,0)
                start = addDays(start, -4)
                end = addDays(end, 14,59,59)
            } else {
                start.setHours(9,0,0)
                start = addDays(start, 0)
                end.setHours(14,59,59)
                end = addDays(end, 1)
            }
            break
          // Tuesday -> Wed
          case 2:
            start.setDate(start.getDate() - 1)
            start.setHours(15,0,0)
            end.setHours(14,59,59)
            end = addDays(end, 1)
            break 
          // Wednesday -> Thursday / Friday
          case 3:
            end.setHours(14,59,59)
            if (end > new Date()){
                start.setHours(15,0,0)
                start = addDays(start, -2)
                end = addDays(end, 14,59,59)
            } else {
                start.setHours(15,0,0)
                start = addDays(start, 0)
                end.setHours(14,59,59)
                end = addDays(end, 1)
            }
            break
          // Thursday -> Friday / Tuesday
          case 4:
            end.setHours(14,59,59)
            if(end > new Date()){
              start.setHours(15,0,0) 
              start = addDays(start, -1)
              end = addDays(end, 0)
            } else {
              end.setHours(14,59,59)
              end = addDays(start, 4)
              start.setHours(15,0,0)
            }
            break
          // Friday -> Tuesday
          case 5:
            end.setHours(14,59,59)
            end = addDays(end, 3)
            start.setHours(15,0,0)
            start = addDays(start, -1)
            break
          // Saturday -> Tuesday
          case 6:
            end.setHours(14,59,59)
            end = addDays(end, 2)
            start.setHours(15,0,0)
            start = addDays(start, -2)
            break
        }
        const facility = "orders" + title
        const orderCollection = fb.db.collection(facility)
        await orderCollection.orderBy("timestamp").where('timestamp', '>', start).
        where('timestamp', '<', end).get()
        .then(function(querySnapshot) {
          querySnapshot.docs.forEach(doc => {
            console.log('document pulled from firebase is: ' + doc)
            orders.push(doc.data());
          })
        })
        return orders
      }

      let pushOrder = async function(form, user, title){
        console.log("In pushOrder")
        console.log('title is: ' + title)
        let orderNum = 'ADS'
        orderNum = orderNum + Math.random().toString(8).substring(2, 8);
        let stamp = new Date();       
        let timestamp = stamp
        stamp = stamp.toString('yyyy-MM-dd')
        let body = '';
        body = JSON.stringify(form.order)
        body = encodeURI(body)

      let createETA = function(){
          let output
          let end = new Date()
          let dc = new Date()
          switch(dc.getDay()){
              // Sunday
              case 0:
                dc.setDate(dc.getDate() + 2)
                output = 'Tuesday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                break
              // Monday
              case 1: 
                end.setHours(14,59,59)
                dc.setDate(dc.getDate() + 1)
                if (end > new Date()){
                  output = 'Tuesday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                } else {
                  dc.setDate(dc.getDate() + 3)
                  output = 'Thursday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                }
                break
              // Tuesday
              case 2:
                dc.setDate(dc.getDate() + 2)
                output = 'Thursday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                break
              // Wednesday
              case 3:
                end.setHours(14,59,59)
                if (end > new Date()){
                  output = 'Thursday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                } else {
                  dc.setDate(dc.getDate() + 2)
                  output = 'Friday ' + (dc.getMonth() + 1) + '/' + (dc.getDate() + 1) + '/' + dc.getFullYear()
                }
                break
              // Thursday
              case 4:
                end.setHours(14,59,59)
                if (end > new Date()) {
                    dc.setDate(dc.getDate() + 1)
                    output = 'Friday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                } else {
                    dc.setDate(dc.getDate() + 5)
                    output = 'Tuesday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                }
                break
              // Friday
              case 5:
                dc.setDate(dc.getDate() + 4)
                output = 'Tuesday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                break
              // Saturday
              case 6:
                dc.setDate(dc.getDate() + 3)
                output = 'Tuesday ' + (dc.getMonth() + 1) + '/' + dc.getDate() + '/' + dc.getFullYear()
                break
          }
          return output
      }
      const orderCollection = fb.db.collection('orders' + title)
      orderCollection.doc(orderNum).set({
            orderName: orderNum,
            date: stamp.split('G')[0],
            facility: title,
            timestamp: timestamp,
            orderInfo: body,
            status: form.status,
            user: user,
            eta: createETA()
        }).then(function(){
            console.log("Document successfully written!")
        })
        .catch(function(error) {
            console.log("Error writing document: ", error)
        });
      }
      // list orders to delete
      let mergeOrders = []
      
      findOrdersBetween(this.state.orderLocation).then( res => {
        res.forEach( order => {
          mergeOrders.push(order.orderName)
          let cur = JSON.parse(decodeURI(order.orderInfo))
          cur.forEach(item => form.order.push(item))
        })
        mergeOrders.forEach(order => {
          const facility = "orders" + this.state.orderLocation
          console.log("facility in mergeOrders: " + facility)
          const orderCollection = fb.db.collection(facility)
          orderCollection.doc(order).delete().then(function() {
            console.log("Order successfully deleted!");
          }).catch(function(error) {
            console.error("Error removing document: ", error);
          });
        }) 
        pushOrder(form, this.state.userProfile[0].name, this.state.orderLocation)
      })
    },
    async createCustom({dispatch}, selectedItems){
      // point to ADScustom
        // console.log("createCustom")
        console.log(selectedItems)
        let createRand = function() {
          return Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
        }
        let addToFacility = async function(item, location){
          const facility = location + 'inventory'
          const orderCollection = fb.db.collection(facility)
          return orderCollection.doc().set({
              'name' : item.name,
              'notes' : '',
              'quantity' : 0,
              'necessary_amount' : 1,
              'SID' : createRand()
          }).then(function(){
            console.log("Document successfully added")
          }).catch(function(error){
            console.log("Error adding document")
          })
        }
        // insert selected items into firebase db
        for(let i = 0; i < selectedItems.length; i++){
          addToFacility(selectedItems[i], this.state.orderLocation)
        }
    },
    async storeCustomItems({dispatch}, items){
        console.log("in storeCustomItem")
        console.log(items)

        let addToFacility = async function(item){
          const facility = 'ADScustom'
          const orderCollection = fb.db.collection(facility)
          return orderCollection.doc().set(item).then(function(){
            console.log("Document successfully added")
          }).catch(function(error){
            console.log("Error adding document")
          })
        }

        for(let i = 0; i < items.length; i++){
          // make sure it is not an ADS item
          if(!items[i].ADSitem){
            addToFacility(items[i])
          }
        }
    },
    async getOrders({dispatch}){
      const orders = [];
      const facility = "orders" + this.state.orderLocation
      const orderCollection = fb.db.collection(facility)
      await orderCollection.orderBy("timestamp", "desc").get()
        .then(function(querySnapshot) {
          querySnapshot.docs.forEach(doc => {
            orders.push(doc.data());
          })
        })
        return orders;
      },
      async getLastOrder({dispatch}){
        const order = [];
        const facility = "orders" + this.state.orderLocation
        const orderCollection = fb.db.collection(facility)
        await orderCollection.orderBy("timestamp").limitToLast(1).get()
        .then(function(querySnapshot) {
          querySnapshot.docs.forEach(doc => {
            order.push(doc.data());
          })
        })
        return order;
      },
      async getInventory({dispatch}){
        const items = [];
        const facility = this.state.orderLocation + 'inventory'
        const orderCollection = fb.db.collection(facility)
        await orderCollection.get()
          .then(function(querySnapshot) {
            querySnapshot.docs.forEach(doc => {
              let temp = doc.data()
              temp['FID'] = doc.id
              items.push(temp);
            })
          })
          return items;
      },
      async getADSInventory({dispatch}){
        const items = [];
        // grab the entire inventory from quickbooks
        const facility ='ADSALLinventory'
        const orderCollection = fb.db.collection(facility)
        await orderCollection.get()
          .then(function(querySnapshot) {
            querySnapshot.docs.forEach(doc => {
              let temp = doc.data()
              temp['ADSitem'] = 1
              temp['FID'] = doc.id
              items.push(temp);
            })
          })
          return items;
      },
      async createItem({dispatch}, form){
        const facility = this.state.orderLocation + 'inventory'
        const orderCollection = fb.db.collection(facility)
        if(!form.notes){
          form.notes = ''
        }
        return orderCollection.doc().set({
            'name' : form.name,
            'notes' : form.notes,
            'quantity' : form.quantity,
            'necessary_amount' : form.necessary_amount
        }).then(function(){
          return "Document successfully added"
        }).catch(function(error){
          return "Error adding document"
        })
      },
      async getItem({dispatch}, id){
        const facility = this.state.orderLocation + 'inventory'
        const orderCollection = fb.db.collection(facility).doc(id)

        return orderCollection.get().then(function(doc) {
          if (doc.exists) {
            return doc.data()
          } else {
            return "No such Document"
          }
        }).catch(function(error){
          return error
        })
      },
      async updateItem({dispatch}, form){
        console.log(form)
        const facility = this.state.orderLocation + 'inventory'
        const orderCollection = fb.db.collection(facility)
        if (!form.notes){
          form.notes = ''
        }
        return orderCollection.doc(form.FID).update({
            'name' : form.name,
            'notes' : form.notes,
            'quantity' : form.quantity,
            'necessary_amount' : form.necessary_amount
        }).then(function(){
          return "Document successfully updated"
        }).catch(function(error){
          return "Error updating document"
        })
      },
      async updateItemQuantity({dispatch}, form){
        console.log("updating item")
        console.log(form)
        const facility = this.state.orderLocation + 'inventory'
        const orderCollection = fb.db.collection(facility)
        return orderCollection.doc(form.FID).update({
            'quantity' : form.quantity
        }).then(function(){
          return "Document successfully updated"
        }).catch(function(error){
          return "Error updating document"
        })
      },
      async deleteItem({dispatch}, id){
        const facility = this.state.orderLocation + 'inventory'
        const orderCollection = fb.db.collection(facility)
        return orderCollection.doc(id).delete().then(function() {
          return "Order successfully deleted!"
        }).catch(function(error) {
          return error
        });
      },
      async removeFacility({dispatch}, facility){
        const ads = 'ADSfacilities'
        const orderCollection = fb.db.collection(ads)
        return orderCollection.doc(facility.FID).delete().then(function(){
          return "Facility has been removed"
        }).catch(function(error){
          return error
        })
      },
      async getFacilities({dispatch}){
        const items = [];
        const ads = 'ADSfacilities'
        const orderCollection = fb.db.collection(ads)
        await orderCollection.get()
          .then(function(querySnapshot) {
            querySnapshot.docs.forEach(doc => {
              let temp = doc.data()
              temp['FID'] = doc.id
              items.push(temp);
            })
          })
          return items;
      },
      async getUsers({dispatch}){
        const items = [];
        const orderCollection = fb.db.collection('users')
        await orderCollection.get()
          .then(function(querySnapshot) {
            querySnapshot.docs.forEach(doc => {
              let temp = doc.data()
              temp['FID'] = doc.id
              items.push(temp);
            })
          })
          return items;
      },
      async addMember({dispatch}, form){
        console.log('in addMember')
        console.log(form)
        let output = []

        let updateDoc = function(formUpdate){
          return fb.db.collection('ADSfacilities').doc(form.FID).update({
            'members' : formUpdate.members,
        }).then(function(){
          return "Document successfully updated"
        }).catch(function(error){
          return ("Error updating document")
        })
        }
        await fb.db.collection('ADSfacilities').doc(form.FID).get().then( doc => {
              if (doc.exists) {
                // console.log("Document data:", doc.data());
                output = doc.data().members
                output.push(form.name)
                let formUpdate = {'FID': form.FID, 'members' : output}
                return updateDoc(formUpdate)
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })
      },
      async updateOrder({dispatch}, form){
        const facility = 'orders' + this.state.orderLocation
        let body = '';
        body = JSON.stringify(form[0])
        body = encodeURI(body)
       return fb.db.collection(facility).doc(form[1]).update({
            'orderInfo' : body,
        }).then(function(){
            return "Document successfully updated"
        }).catch(function(error){
            return "Error updating document"
        })
      },
      async deleteOrder({dispatch}, id){
        const facility = 'orders' + this.state.orderLocation
        const orderCollection = fb.db.collection(facility)
        return orderCollection.doc(id).delete().then(function() {
          return 1
        }).catch(function(error) {
          return 0
        });
      },
      async updateUser({dispatch}, form){
        return fb.db.collection('users').doc(form.FID).update({
            'additional' : form.additional,
        }).then(function(){
            return "Document successfully updated"
        }).catch(function(error){
            return "Error updating document"
        })
      },
//////////////////////////////////////////////////////////////////
      async createAlert({dispatch}, form){
        const collection = fb.db.collection('ADSAlerts')
        return collection.doc().set({
            'title' : form.title,
            'subject' : form.subject,
            'date' : new Date(),
        }).then(function(){
          return "Document successfully added"
        }).catch(function(error){
          return "Error adding document"
        })
      },
      async getAlerts({dispatch}){
        const items = [];
        // grab the entire inventory from quickbooks
        const alerts ='ADSAlerts'
        const orderCollection = fb.db.collection(alerts)
        await orderCollection.get()
          .then(function(querySnapshot) {
            querySnapshot.docs.forEach(doc => {
              let temp = doc.data()
              temp['FID'] = doc.id
              items.push(temp);
            })
          })
          return items;
      },
//////////////////////////////////////////////////////////////////////
      async createFacility({dispatch}, form){
        console.log("we will create a facility")
        const inventory = form.name + 'inventory'
        const archive = form.name + 'archive'
        const orders = 'orders' + form.name

        /////// Add to list of facilities //////
        fb.db.collection('ADSfacilities').add({
            'name' : form.name,
            'members' : []
            }).then(function(){
              return "Document successfully updated"
            }).catch(function(error){
              console.log("Error updating document")
            })
        ///////////////////////////////////////////
        fb.db.collection(inventory).add({
          name: "ADS",
          necessary_amount: 1,
          notes: "Welcome to ADS",
          quantity: 0
          })
          .then(function(docRef) {
            console.log("Inventory collection written with ID: ", docRef.id);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });

        fb.db.collection(orders).add({
          orderName: "ADS000001",
          facility: form.name,
          date: "N/A",
          eta: "N/A",
          internalComment: "N/A",
          status: 2
          })
          .then(function(docRef) {
            console.log("Order collection written with ID: ", docRef.id);
          })
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });

          fb.db.collection(archive).add({
            orderName: "ADS000001",
            facility: form.name,
            date: "N/A",
            eta: "N/A",
            internalComment: "N/A",
            status: 2
            })
            .then(function(docRef) {
              console.log("Archive collection written with ID: ", docRef.id);
            })
            .catch(function(error) {
              console.error("Error adding document: ", error);
            });
      },
//////////////////////////// Manager functions /////////////////////////////////////////////////
  },
  modules: {
  }
})
