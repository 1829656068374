import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyAYAR3fkSz0X_c3JCyOOOXImMa4snTww_s",
    authDomain: "w3-ads.firebaseapp.com",
    databaseURL: "https://w3-ads.firebaseio.com",
    projectId: "w3-ads",
    storageBucket: "w3-ads.appspot.com",
    messagingSenderId: "379297206732",
    appId: "1:379297206732:web:28a3b5d5d48881a4b14d69",
    measurementId: "G-LPGCBJ52J9"
}

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const ordersCollection = db.collection('ordersADS')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  ordersCollection,
}
