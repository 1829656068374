import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/phase',
    name: 'phase',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/Phase.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/room/:id',
    name: 'room',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/Room.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/Recommend.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/allItems',
    name: 'allItems',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/AllItems.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createItem',
    name: 'createItem',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/CreateItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/QR.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/print',
    name: 'print',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/Print.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/Orders_v2.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mOrder',
    name: 'mOrder',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/mOrder.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/deleteItem',
    name: 'deleteItem',
    component: () => import(/* webpackChunkName: "createItem" */ '../views/DeleteItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
