import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import axiosApi from 'axios';

const axios = axiosApi.create({
  baseURL: 'http://localhost:8080/',
  headers: {'Authorization': `Bearer: sk_sortly_NsLFv_JoAwsyCLNsJX5C`}
})

window.axios = axios;

Vue.config.productionTip = false

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      axios,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
